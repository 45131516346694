<template>
  <v-container>
    <v-row>
      <v-col class="text-center">
        <h2>Briefing Confirmation</h2>
        <p>Your briefing request has been received. Please allow us 2-3 business days to review. We will be in touch as soon as possible.</p>
        <p>If you need to get into contact with us urgently, please call the number below during business hours.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default ({
})
</script>
